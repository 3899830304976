import * as en from '../i18n/locales/en.json';
import * as ar from '../i18n/locales/ar.json';
import * as de from '../i18n/locales/de.json';
import * as fi from '../i18n/locales/fi.json';
import * as he from '../i18n/locales/he.json';
import * as ja from '../i18n/locales/ja.json';
import * as pt from '../i18n/locales/pt.json';
import * as zh from '../i18n/locales/zh.json';

export default class AnI18NextLibHelper {
    public static loadLang = (lang: string) => {
        let anI18Nextlib: any = en;

        switch (lang) {
            case 'ar':
                anI18Nextlib = ar;
                break;
            case 'de':
                anI18Nextlib = de;
                break;
            case 'en':
                anI18Nextlib = en;
                break;
            case 'fi':
                anI18Nextlib = fi;
                break;
            case 'he':
                anI18Nextlib = he;
                break;
            case 'ja':
                anI18Nextlib = ja;
                break;
            case 'pt':
                anI18Nextlib = pt;
                break;
            case 'zh':
                anI18Nextlib = zh;
                break;
            default:
                break;
        }

        return anI18Nextlib['default'];
    };

    private static translate = (dict: any, keys: string[]): string => {
        let output = '';

        if (dict && typeof dict === 'object' && keys?.length > 0) {
            const val = dict[keys[0]];

            if (val && typeof val === 'object' && keys?.length > 1) {
                output = AnI18NextLibHelper.translate(val, keys.slice(1));
            } else {
                output = val;
            }
        }

        return output;
    };

    public static Translate = (dictOrig: any, key: string, subs?: any): string => {
        let output = AnI18NextLibHelper.translate(dictOrig ?? AnI18NextLibHelper.loadLang(''), key?.split('.'));

        if (output && subs) {
            for (const fld in subs) {
                // Note:  replaceAll() break PR pipeline
                // output = output.replaceAll(token, `${subs[fld]}`);

                const token = `{{${fld}}}`;

                while (output.indexOf(token) > -1) {
                    output = output.replace(token, `${subs[fld]}`);
                }
            }
        }

        return output;
    };
}
